<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputError from '@/Components/InputError.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';

defineProps({
    status: {
        type: String,
    },
    users: {
        type: Array,
        default: []
    },
});

const form = useForm({
    email: '',
    password: '',
});

const submit = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <GuestLayout>

        <Head title="Log in" />

        <template v-if="users" v-slot:noGutter>
            <div class="shopFloorLoginSection pb-5 pt-5">
                <v-row>
                    <v-col cols="1"></v-col>
                    <v-col cols="10">
                        <div class="d-flex flex-wrap">
                            <div class="text-h4 text-white ml-3 mb-1 mt-5 mr-4 bold text-nowrap" color="white">Select Shop Floor User...</div>
                            <v-btn 
                                v-for="user in users" v-bind:key="user.id"
                                color="primaryshade"
                                class="tabletLoginButton my-4 mx-4"
                                :href="route('tablet.login', user)"
                                size="x-large">
                                {{ user.forename }} {{ user.surname }}
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col cols="1"></v-col>

                </v-row>
            </div>
        </template>

        <v-alert v-if="status" class="mb-5" type="success" :text="status"></v-alert>
        <form @submit.prevent="submit">
            <v-row>
                <v-col col="4"></v-col>
                <v-col cols="4">

                    <div class="text-h6 ml-3 mb-1 mt-5 font-weight-bold">
                        <span v-if="users">Or login for admin area...</span>
                        <span v-else>Login for admin area...</span>
                    </div>

                    <div>
                        <TextInput id="email" type="email" label="Username *" color="white" class="mt-1 block w-full"
                            v-model="form.email" :errormessages="form.errors.email" required autofocus
                            autocomplete="username" />
                    </div>

                    <div class="mt-4">
                        <TextInput id="password" type="password" label="Password *" class="mt-1 block w-full"
                            v-model="form.password" :errormessages="form.errors.password" required
                            autocomplete="current-password" />
                    </div>

                    <div class="flex items-center justify-end mt-4">

                    </div>



                    <v-row>
                        <v-col>
                            <PrimaryButton variant="flat" class="ms-4 float-right"
                                :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                                Log in
                            </PrimaryButton>
                        </v-col>
                    </v-row>


                </v-col>
                <v-col col="4"></v-col>
            </v-row>
        </form>

    </GuestLayout>
</template>
<style scoped>
.v-text-field input {
    color: rbg(var(--v-theme-white)) !important;
}

.shopFloorSection {
    background-color: rbg(var(--v-theme-textdim)) !important;
}

.tabletLoginButton {
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shopFloorLoginSection {
    background-color: rgb(var(--v-theme-textdim)) !important;
    border-bottom: 5px rgb(var(--v-theme-primary)) solid;
}
</style>
